export default function({ store, redirect, route }) {
    let scope = route.meta.reduce((acc, meta) => meta.scope || acc, undefined)
    if (!scope) {
        console.warn(`${route.path} doesn't have a user scope, please assign one in the meta. Nuxt will now assume scope is "guest"`)
        scope = 'guest'
    }

    if (scope === 'impersonator' && !store.$auth.hasScope(scope) && !store.$api.auth.impersonator()) {
        return redirect('/forbidden')
    }

    if (scope === 'customer' && !store.$auth.hasScope(scope)) {
        if (store.$auth.hasScope('impersonator')) {
            return redirect('/impersonate')
        }
        return redirect('/forbidden')
    }
}
