/**
 * Date Time options:
 * era: narrow|short|long
 * year: numeric|2-digit => yy|y ~ 2019|19
 * month: numeric|2-digit|short|long|narrow => m|mm|mmm|mmmm|mmmmm ~ 01|1|Apr|April|A
 * day: numeric|2-digit => d|dd ~ 1|01
 * weekday: narrow|short|long => w|ww|www ~ M|Mon|Monday
 * hour: numeric|2-digit => h|hh ~ 1|01
 * minute: numeric|2-digit => i|ii ~ 1|01
 * second: numeric|2-digit => s|ss ~ 1|01
 * timeZoneName: short|long
 */
// const dateTimeFormats = {
//     dmy: {
//         day: 'numeric',
//         month: 'numeric',
//         year: '2-digit',
//     },
//     ddmmy: {
//         day: '2-digit',
//         month: '2-digit',
//         year: '2-digit',
//     },
//     ddmmyy: {
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric',
//     },
//     ddmmmyy: {
//         day: '2-digit',
//         month: 'short',
//         year: 'numeric',
//     },
//     dmmmmyy: {
//         day: '2-digit',
//         month: 'long',
//         year: 'numeric',
//     },
//     hhii: {
//         hour: '2-digit',
//         minute: '2-digit',
//     },
// }

/**
 * Number options:
 * style => decimal|currency|percent
 * currencyDisplay => symbol|code|name
 * currency => ISO 4217 currency codes eg.: EUR|USD|GBP|CNY
 * @doc https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */
// const numberFormats = {
//     'en-GB': {
//         currency: {
//             style: 'currency',
//             currencyDisplay: 'symbol',
//             currency: 'GBP',
//         },
//         decimal: {
//             style: 'decimal',
//         },
//         percent: {
//             style: 'percent',
//         },
//     },
//     'it-IT': {
//         currency: {
//             style: 'currency',
//             currencyDisplay: 'symbol',
//             currency: 'EUR',
//         },
//         decimal: {
//             style: 'decimal',
//         },
//         percent: {
//             style: 'percent',
//         },
//     },
// }

const config = {
    defaultLocale: 'en',
    vueI18n: {
        fallbackLocale: 'en',
    },
    strategy: 'prefix_except_default',
    langDir: '~/locales/',
    lazy: true,
    locales: [
        { code: 'en', iso: 'en', file: 'index.js', dir: 'ltr', iso2: 'gb', name: 'English' },
        { code: 'fr', iso: 'fr', file: 'index.js', dir: 'ltr', iso2: 'fr', name: 'Français' },
    ],
    detectBrowserLanguage: {
        fallbackLocale: 'en',
        redirectOn: 'all',
        useCookie: false,
    },
}

const SUPPORTED_LANGUAGES = config.locales.map(l => l.iso) 

export default config


/* Strip language from start of route. Almost definitely shouldn't be in this file */
const LANGUAGE_CODE_REGEX = new RegExp(`^((${SUPPORTED_LANGUAGES.join('|')})(\/|$))+`)
export function stripCountryFromRoute(path) {
    if (!path || typeof path !== 'string') return
    const rest = path.toLowerCase()
      .replace(/^\/+/, '')
      .replace(/\/+$/, '')
      .replace(LANGUAGE_CODE_REGEX, '')

    if (rest.length)
      return rest
}