import { statusCodes } from '~/utils/api'
import { getCommodityByRoute, knownCommodities } from '~/utils/commodities'

export default function({ route, error }) {
    if (route.meta.some(meta => meta.isCommodity)) {
        const maybeCommodity = getCommodityByRoute(route.path)
        if (!maybeCommodity ||
          !maybeCommodity.commodity ||
          !knownCommodities.includes(maybeCommodity.commodity)) {
            return error({ statusCode: statusCodes.NOT_FOUND })
        }
    }
}
